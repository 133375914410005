import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();


//
//
//

// Handle Contact Form
$('#c_submit').click(function() {
	var name = $('#c_name').val();
	var email = $('#c_email').val();
	var phone = $('#c_phone').val();
	var message = $('#c_message').val();

	if (!name || !message || email.search(/^\s*[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]+\s*$/i) < 0) {
		$('#c_error').show();
	} else {
		$.post('/assets/php/sendit.php',{
			name: name,
			email: email,
			phone: phone,
			message: message
		});
		$('#c_error').hide();
		$('#c_form').slideUp(800, function() { 
			$('#c_thank').slideDown(800);
		});
	}
});

